<template>
  <Page>
    <v-layout row wrap slot="content" class="body-1">
      <v-flex xs12 class="text-left">
        <h3 class="title mb-4">
          SEL de mer, le système d'échange de services en Gaspésie!
        </h3>
        <p>
          Vous avez besoin d'aide pour récolter vos légumes ou réparer votre toit? Vous avez du temps à donner
          pour enseigner une langue, ou la musique? Vous partez en vacances et voulez faire garder votre chat,
          ou arroser vos plantes? Vous pouvez aider quelqu'un à corriger son CV, ou construire un site
          internet? Et si vous pouviez offrir de cuisiner un gâteau et recevoir un massage en échange?
        </p>
        <p>
          Le SEL de mer permet des transactions en heures. La philosophie dont il s'inspire renvoie à des
          systèmes d'échange de services tels l'Accorderie, les SEL (service d'échange local) ou les JEU
          (jardin d'échange universel), dont la plupart sont basés sur un principe de 1h vaut 1h (peu importe,
          donc, la tâche effectuée).
        </p>
        <p>
          Deux outils ont été mis sur pied pour faciliter la mise en relation de personnes ayant des besoins,
          et d'autres ayant les moyens d'y répondre... le tout dans une volonté de partage et d'échange.
        </p>
        <ul>
          <li>
            D'abord, une page facebook permet d'offrir ou de demander des biens ou des services qui sont
            plus spontannés ou ponctuels, et/ou qui impliquent plus des échanges sous forme de troc.
          </li>
          <li>
            Le site internet permet de se construire un profil, sur lequel on annonce des offres de services
            durables dans le temps, ainsi que les échanges effectués. Également, chaque membre a une banque
            d'heures. Ces banques de temps permettent à quelqu'un de recevoir un service, par exemple, sans
            nécessairement en donner un en retour directement, comme c'est le cas avec le troc. Les heures
            sont ainsi accumulées ou débitées d'un compte qui permet d'élargir les échanges à toute la
            communauté.
          </li>
        </ul>
        <p>
          Ce sont les usagers-usagères entre elles et eux qui doivent convenir de la "monnaie d'échange":
          heures, demi, troc de bien ou service?
        </p>
        <p>
          Le SEL de mer est un espace autogéré pour afficher des offres de services ou de biens et qui permet
          également d'enregistrer des transactions d'heures. Le système est basé sur la confiance. Et nous
          espérons que cet outil contribuera à l'essor de la confiance dans la communauté!
        </p>
        <ul>
          <li>
            <a href="http://www.facebook.com/groups/seldemergaspesie/">
              Page Facebook
            </a>
          </li>
          <li>
            <a href="http://sel.horizonsgaspesiens.net">
              Application Web
            </a>
          </li>
        </ul>
      </v-flex>
    </v-layout>
  </Page>
</template>

<script>

export default {
  name: "SelDeMer",
  components: {
    Page: () => import("@/components/PageWrap.vue")
  }
}
</script>

<style scoped>

</style>
