var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"vh-center font-weight-thin pr-6",staticStyle:{"white-space":"nowrap"},attrs:{"row":"","wrap":"","id":_vm.anchor}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{class:{
              'mt-5 mb-12': _vm.$vuetify.breakpoint.mdAndUp,
              'pl-4 mt-5 mb-4': _vm.$route.name === 'home' && _vm.$vuetify.breakpoint.smAndDown
          },attrs:{"flat":""}},[_c('v-layout',{attrs:{"reverse":_vm.imageAtRight,"row":"","wrap":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","md5":""}},[(_vm.image && _vm.isCarousel)?_c('v-carousel',{attrs:{"width":"100%"}},_vm._l((_vm.image),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":require('../assets/' + item)}})}),1):_vm._e(),(_vm.image && !_vm.isCarousel)?_c('v-img',{class:{
                                  'ml-5': _vm.$vuetify.breakpoint.mdAndUp
                              },attrs:{"src":require('../assets/' + _vm.image),"contain":"","top":""}}):_vm._e(),_vm._t("image")],2),_c('v-flex',{attrs:{"xs12":"","md7":"","left":""}},[_c('v-card-title',{staticClass:"mt-0 pt-0 text-h5 text-left font-weight-bold",class:{
                          'mt-4' : _vm.$vuetify.breakpoint.smAndDown
                      },attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("title")],2),_c('v-card-subtitle',{staticClass:"text-smaller font-weight-bold text-left mt-1 ml-4"},[_vm._t("subtitle")],2),_c('v-card-text',{staticClass:"text-left text"},[_vm._t("content")],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }